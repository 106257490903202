import { styled } from "@mui/material"

import { theme } from "../theme"
import { MainLogo } from "../ui/MainLogo"
import { HeaderMenu } from "../ui/HeaderMenu"

interface HeaderProps {
    children: React.ReactNode
    onClick: () => void
}

export const Header = ({ children, onClick }: HeaderProps) => {
    return (
        <StyledHeader>
            <MainLogo />

             <HeaderMenu title={children} onClick={onClick} />
        </StyledHeader>
    )
}

const StyledHeader = styled('header')({
    minHeight: '5rem',
    padding: '1rem 2rem',
    backgroundColor: theme.palette.primary.light,
    background: 'url(/bg.png)',
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 1,
    color: '#fff',
    backgroundSize: 'cover',
})
