import { CircularProgress } from "@mui/material"
import { styled } from "@mui/styles"

export const LoadingPage = () => {
    return (
        <StyledWrapper>
            <CircularProgress />
        </StyledWrapper>
    )
}

const StyledWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
})
