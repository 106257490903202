import { MUIDataTableOptions } from "mui-datatables";

export const tableOptions: MUIDataTableOptions = {
  sort: false,
  pagination: false,
  filter: false,
  selectableRows: 'multiple',
  rowHover: true,
  rowsExpanded: [0, 1],
  filterType: 'dropdown',
  resizableColumns: false,
  fixedSelectColumn: true,
  selectableRowsOnClick: false,
  textLabels: {
      body: {
          noMatch: 'Sorry, geen resultaten'
      }
  }
}

export const fileHiddenLabel = 'content'

export const fileNameLabel = 'content_filename'

export const links = '_links'
