import { styled } from "@mui/material"
import { NavLink } from "react-router-dom"

export const MainLogo = () => {
    return (
        <StyledWrapper>
            <NavLink to='/'>
                <img src="../customer.png" alt="RGF Staffing" />
                <span></span>
            </NavLink>
        </StyledWrapper>
    )
}

const StyledWrapper = styled('div')({
    display: 'inline-block',
    margin: '0 auto',
    position: 'relative',
    '& img': {
        height: '2.5rem',
        width: 'auto',
        position: 'relative',
        zIndex: 1,
    },
    '& span': {
        position: 'absolute',
        left: 0,
        right: 0,
        height: 0,
        top: '57%',
        boxShadow: '0px 0px 34px 9px #fff',
        zIndex: 0,
    }
})
