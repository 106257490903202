import { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';
import Draggable, { DraggableData } from 'react-draggable';
import { styled } from '@mui/material';
import cx from 'classnames'

import { PdfViewer } from '../../PdfViewer/PdfViewer';
import { calculateDraggablePane, getTableColumnSizes } from './helpers';
import { TableBody } from './components/TableBody';
import { theme } from '../../../theme';
import { useLocation } from 'react-router-dom';
import { ProfileQueryEmbeddedItemData } from '../../../repository/models'

interface TableProps {
    rows: ProfileQueryEmbeddedItemData[] | null,
    title: string,
}

export const Table = ({ rows, title }: TableProps) => {
    const [ contentUrl, setContentUrl ] = useState('')
    const [ pdfLoaded, setPdfLoaded ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ dividerLocation, setDividerLocation ] = useState(0)
    const [ opened, setOpened ] = useState(false)
    const location = useLocation()
    const [index, setIndex] = useState<number | null>(null)

    const handleDrag = (data: DraggableData) => {
        setDividerLocation((window.outerWidth/2) + data.x)
    }

    useEffect(() => {
        // Should be updated later, size on block when spinner is running
        if(loading){
            setDividerLocation(window.outerWidth/2)
        }
        if(pdfLoaded){
            setDividerLocation(window.outerWidth/2)
        }
        if(opened){
            setDividerLocation(window.outerWidth/2)
        }
    }, [pdfLoaded, loading, opened, setDividerLocation])

    const draggableData = calculateDraggablePane()

    const handleClose = () => {
        setContentUrl('')
        setPdfLoaded(false)
        setDividerLocation(0)
        setOpened(false)
        setLoading(false)
        setIndex(null)
    }

    useEffect(() => () => {
        handleClose()
    }, [])

    useEffect(() => {
        handleClose()
    },[location])

    useEffect(() => {
        if(index !== null && !!rows){
            const selectedRow = rows[index]
            const rowLinks = selectedRow._links
            const contentUrl = rowLinks.content?.href

            setContentUrl(contentUrl)
            setOpened(true)
            return
        }
        handleClose()
    }, [ index, setContentUrl, setOpened, rows ])

    const classes = cx({ 'is-hidden': rows === null })

    const leftColumnRef = useRef<HTMLDivElement>(null)
    const rightColumnRef = useRef<HTMLDivElement>(null)

    getTableColumnSizes(
        rightColumnRef,
        leftColumnRef,
        opened,
        !!contentUrl,
        dividerLocation
    )

    return (
        <StyledTable className={classes}>
            <TableBody
                ref={leftColumnRef}
                rows={rows ?? []}
                title={title}
                selectedIndex={index}
                onSelectIndex={setIndex}
            />

            <Draggable
                axis="x"
                bounds={draggableData}
                onDrag={(_e, value) => handleDrag(value)}
            >
                {!!contentUrl ? (<StyledDivider style={{left: dividerLocation}} />) : (<></>)}
            </Draggable>

            <ViewerWrapper
                ref={rightColumnRef}
                url={contentUrl}
                pdfLoaded={pdfLoaded}
                onLoad={() => setPdfLoaded(true)}
                onLoading={setLoading}
                onClose={handleClose}
            />
        </StyledTable>
    )
}

const StyledTable = styled('div')({
    display: 'flex',
    width: '100%',
    '&.is-hidden': {
        opacity: 0
    }
})

const StyledDivider = styled('span')({
    height: '100%',
    minWidth: '3rem',
    transform: 'none !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'col-resize',
    marginTop: '1.5rem',
    '&::before': {
        content: '""',
        display: 'block',
        width: '12px',
        height: '20px',
        borderLeft: '3px solid '+theme.palette.primary.main,
        borderRight: '3px solid '+theme.palette.primary.main,
        transition: 'width 150ms ease,height 150ms ease,transform 150ms ease,background-color 150ms ease,-webkit-transform 150ms ease'
    },
    '&:hover': {
        '&::before': {
            borderColor: theme.palette.primary.dark,
            transition: 'transform 200ms eae-out',
            transform: 'scale(1.2)',
        }
    },
})

interface ViewerWrapperProps {
    url: string,
    pdfLoaded: boolean,
    onLoad: () => void,
    onLoading: (value: boolean) => void,
    onClose: () => void,
}

export const ViewerWrapper = forwardRef(({
    url,
    pdfLoaded,
    onLoad,
    onLoading,
    onClose
}: ViewerWrapperProps, ref: ForwardedRef<any>) => {
    return (
        <StyledRightColumn ref={ref}>
            <PdfViewer pdfLoaded={pdfLoaded} url={url} onLoad={onLoad} onLoading={onLoading} onClose={onClose} />
        </StyledRightColumn>
    )
})

const StyledRightColumn = styled('div')({
    height: 'calc(100vh - 372px)',
    '& ::-webkit-scrollbar': {
        display: 'none'
    },
})
