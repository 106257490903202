import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";

import { ProfileQueryData } from "../repository/models"
import { HttpResponseError } from "../repository/HttpError"
import fetcher from '../repository';

const exceptionJaaropgaveSlug = 'jaaropgave-documents'
const exceptionFactuurSlug = 'factuur-documents'
const exceptionLijstwerkSlug = 'lijstwerk-documents'

export const useQueryEntityData = (slug: string) => {
  const location = useLocation()
  const [data, setData] = useState<ProfileQueryData | null>(null)
  const [error, setError] = useState<HttpResponseError | null>(null)

  useEffect(() => {
    if(!!slug){
        setData(null)
        const method = getMethod(slug)
        fetcher[method](slug, location.search).then(result => {
            setData(result)
            setError(null)
        }).catch(error => {
            setError(error)
        })
    }
  }, [slug, location])

  return { data, error }
}

const getMethod = (slug: string) => {
    switch (slug){
        case exceptionJaaropgaveSlug:
            return 'getQueryJaaropgaveData';
        case exceptionFactuurSlug:
            return 'getQueryFactuurData';
        case exceptionLijstwerkSlug:
            return 'getQueryLijstwerkData';
        default:
            return 'getQueryData';
    }
  }
