import { RJSFValidationError } from "@rjsf/utils"

import { ProfileEntityData } from "../../repository/models"

const fileHiddenLabel = 'content'

export const updateFiledsWithIntegerType = (value?: ProfileEntityData) => {
    if(!!value){
        const properties = Object.entries(value.properties)
     
        const propertiesWithoutInteger = properties.map(([title, body]) => {
            if('type' in body && body.type === 'integer'){
                return [ title, { ...body, type: 'string' }]
            }
            return [title, body]
        })

        const newProperties = propertiesWithoutInteger.reduce((prev, [title, body]) => ({ ...prev, [title as string]: body }), {})

        return { ...value, properties: newProperties }
    }
}

export const hideDateFields = (value?: ProfileEntityData) => {
    if(!!value){
        const properties = Object.entries(value.properties)

        const updatedProperties = properties.filter(([, body]) => {
            const isFormatInBody = 'format' in body
            const isDatesField = body?.format === 'date' || body?.format === 'date-time'
            return !isFormatInBody && !isDatesField
        })

        const newProperties = updatedProperties.reduce((prev, [name, body]) => ({ ...prev, [name]: body }), {})
        return {
            ...value,
            properties: newProperties
        }    
    }

    return value
}  

export const getFieldsWithoutHidden = (value?: ProfileEntityData) => {
  if(!!value){
      const propertyList = Object.entries(value.properties)
      const updatedProperyList = propertyList.filter(([name]) => !name.startsWith(fileHiddenLabel))
      const propertyListWithoutReadOnly = updatedProperyList.filter(([, body]) => body?.readOnly !== true)
      const newProperties = propertyListWithoutReadOnly.reduce((prev, [name, body]) => ({ ...prev, [name]: body }), {})

      return {
          ...value,
          properties: newProperties
      }
  }
  return null
}

export const transformErrors = (errors: RJSFValidationError[]) => {
  return errors.map(error => {
      if (error.params?.format === "date") {
          error.message = "Onjuiste datumnotatie"
      }
      return error
  })
}

export const checkOnDate = (value: string) => {
    return !!value.match(/^(\d{4})-(\d{2})-(\d{2})$/);
}

export const convertToIso = (value: string) => {
    const date = (value).split('-')
    return new Date(Number(date[0]), Number(date[1])-1, Number(date[2]), +1).toISOString()
}

export const convertFromIso = (value: string) => {
    const event = new Date(Date.parse(value))

    event.setHours(event.getHours() + 12)
    event.setHours(0,0,0)
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' } as const;

    return event.toLocaleDateString("nl", options).replaceAll('-','/')
}

export const typeDate = (val: string) => {
    const numbers = val.replace(/[^\d]/g, '')
    const date = numbers.split('').map((item, index) => {
        if(index === 2 || index === 4){
            return '/'+item
        }
        return item
    })
    return date.join('')
}

export const replaceDateSigns = (value: string, first: string, second: string) => {
    const date = value.split(first)
    const dateString = date[2] + second + date[1] + second + date[0]
    return(`${dateString}`)
}
