import { Box, MenuItem, Select } from "@mui/material";
import { TextInput } from "./TextInput";
import { WidgetProps } from "@rjsf/utils";

import { ClearButton } from "../button/ClearButton";
import { makeStyles } from "@mui/styles";

export const TextGroup = (props: WidgetProps) => {
    const classes = useStyles();
    const isSelect = props.name === 'mh_sjabloontype';

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if(event.key === 'Enter'){
            document.getElementById('submit-button')?.click()
        }
    }

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: '11rem 1fr', columnGap: '1rem', alignItems: 'center' }}>
            <label htmlFor={props.id}>{props.label}</label>

            {isSelect ? (
                 <Select
                    className={classes.select}
                    value={props.value || ''}
                    onChange={(e) => props.onChange(e.target.value)}
                >
                    <MenuItem className={classes.option} value=''><i>Any</i></MenuItem>
                    <MenuItem className={classes.option} value='intake'>INTAKE</MenuItem>
                    <MenuItem className={classes.option} value='placement'>PLACEMENT</MenuItem>
                </Select>
            ) : (
                <>
                    <TextInput
                        {...props}
                        id={props.id}
                        value={props.value || ''}
                        placeholder={props.placeholder}
                        handleOnChange={props.onChange}
                        onBlur={() => props.onBlur(props.id, props.value)}
                        onFocus={() => props.onFocus(props.id, props.value)}
                        handleOnKeyDown={handleKeyDown}
                    />

                    {!!props.value && (
                        <ClearButton onClear={() => props.onChange('')} />
                    )}
                </>
            )}
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    select: {
        width: theme.spacing(18),
        height: theme.spacing(5),
        maxWidth: theme.spacing(18),
    },
    option: {
        height: theme.spacing(5),
        cursor: 'pointer',
        fontSize: theme.typography.body2.fontSize,
        borderRadius: theme.shape.borderRadius
    }
}));
