import { RefObject } from "react"

import { ProfileEntityData, ProfileQueryEmbeddedItemData } from "../../../repository/models";
import { convertFromIso } from "../../../components/SearchForm/helpers";
import { fileHiddenLabel, fileNameLabel, links } from "./variables";

export const calculateDraggablePane = ():{left?: number, top?: number, right?: number, bottom?: number} | string => {
  let leftBoundary = 0 - (window.outerWidth/2) + 320;
  let rightBoundary = (window.outerWidth/2) - 320;
  return {left: leftBoundary, right: rightBoundary};
}

export const getTitles = (rows: ProfileQueryEmbeddedItemData[]) => {
  if(!!rows[0]){
      const keys = Object.keys(rows[0])
      const keysWithoutLinks = keys.filter(item => item !== links)
      
      return keysWithoutLinks.filter(item => !item.startsWith(fileHiddenLabel) || item === fileNameLabel)
  }
  return []
}

export const getColumns = (rows: ProfileQueryEmbeddedItemData[], data: ProfileEntityData|null) => {
  if(data !== null){
    const titles = getTitles(rows)

    return titles.map(item => {
      const isWerknemer = Object.keys(data.properties)[0].includes('werknemer.')
      const isDebiteur = Object.keys(data.properties)[0].includes('debiteur.')

      if(item === 'jaar_opgave_jaar'){
        return createColumn(item, 'Jaar opgave jaar')
      }
      if(item === 'content_filename'){
        return createColumn(item, 'Content filename')
      }
      if(isWerknemer){
        const label = data.properties[`werknemer.${item}`]?.title
        return createColumn(item, label)
      }
      if(isDebiteur){
        const label = data.properties[`debiteur.${item}`]?.title
        return createColumn(item, label)
      }
      const label = data.properties[item]?.title
      return createColumn(item, label)
    })
  }
  const titles = getTitles(rows)

  return titles.map(item => createColumn(item))
}

export const createColumn = (value: string, label?: string) => {
  const labelWithoutUnderscore = value?.replaceAll('_', ' ')
  const updatedLabel = labelWithoutUnderscore.charAt(0).toUpperCase() + labelWithoutUnderscore.slice(1);
  return ({
      name: value,
      label: !!label ? label : updatedLabel,
      options: {
          filter: true,
          sort: true,
          display: value === 'id' ? false : true
      }
  })
}

export const updateRowDates = (rows: ProfileQueryEmbeddedItemData[]) => {
  return rows.map(row => {
    const entries = Object.entries(row)
    const newEntries = entries.map(([key, value]) => {
      if(typeof value === 'string'){
        
        if(isIsoDate(value.trim())){
          return { [key]: convertFromIso(value.trim()) }
        }

        const list = value.trim().split(' ')
        if(!!list.find(item => isIsoDate(item.trim()))){
          const newList = list.map(item => {
            if(isIsoDate(item.trim())){
              return convertFromIso(item.trim())
            }
            return item
          })
          return { [key]: newList.join(' ● ') }
        }

        return { [key]: value }
      }

      return { [key]: value }
    })

    const newValue: { [k: string]: any } = {}
    newEntries.forEach((item) => {
      const [key, value] = Object.entries(item)[0]
      newValue[key] = value
    })

    return newValue
  })
}

const isIsoDate = (str: string) => {
  if(!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str) && !/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/.test(str)){
    return false
  }
  
  const d = new Date(str)
  return d instanceof Date && !isNaN(Number(d)); // valid date 
}

export const getTableColumnSizes = (
    rightColumnRef: RefObject<HTMLDivElement>,
    leftColumnRef: RefObject<HTMLDivElement>,
    openedViewer: boolean,
    urlPresent: boolean,
    dividerLocation: number
) => {
    const leftWidth = ((dividerLocation/window.outerWidth) * 100) -1;
    const rightWidth = (((window.outerWidth - dividerLocation)/window.outerWidth) * 100) - 1;

    if(!!leftColumnRef?.current){
        leftColumnRef.current.style.width = urlPresent ? `${leftWidth}%` : '100%'
    }

    if(!!rightColumnRef?.current){
        const page = rightColumnRef.current?.querySelector<HTMLElement>('.rpv-core__inner-page')
        rightColumnRef.current.style.height = !!page ? `${page?.offsetHeight+64}px` : '100%'

        if(!openedViewer){
            rightColumnRef.current.style.width = '0%'
            return 
        }

        if(urlPresent){
            rightColumnRef.current.style.width = `${rightWidth}%`
        }
    }
}
