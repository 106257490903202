import { Box, Typography } from "@mui/material"
import { ReactNode } from "react"

interface BlockTitleProps {
    mb?: number;
    children: ReactNode;
}
export const BlockTitle = ({ mb = 2, children }: BlockTitleProps) => {
    return (
        <Box mb={mb}>
            <Typography variant="h6">
                <Box sx={{ fontWeight: 500 }}>{children}</Box>
            </Typography>
        </Box>
    )
}
