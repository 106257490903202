import oidcConfig from "./oidc-config"

const productionHost = 'rgfsnl.contentgrid.app'

export function getConfig() {
  if(isProduction()){
      return oidcConfig[0]
  }

  return JSON.parse(window.localStorage.getItem('config')!!) ?? oidcConfig[0]
}

export function isProduction() {
  return window.location.hostname === productionHost
}