import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";

import fetcher from './../repository';
import { ProfileList } from "../repository/models";
import { PageBlockWrapper } from "../ui/PageBlockWrapper";
import { NavMenu } from '../ui/NavMenu';
import { Signature } from '../ui/Signature';

const notUsableParameter = 'self'

export const Sidebar = () => {
    const { slug } = useParams()
    const [ options, setOptions ] = useState<{label: string, value: string}[]>([])

    useEffect(() => {
        fetcher.getProfileData()
            .then((data) => {
                const optionList = getOptions(data)
                setOptions(optionList)
            })
        }, [])

    return (
        <PageBlockWrapper isSidebar title="Entiteiten">
            <NavMenu selected={slug ?? ''} options={options} />

            <Signature />
        </PageBlockWrapper>
    )
}

const getOptions = (data: ProfileList) => {
    if(!!data){
        const entries = Object.entries(data._links)
        const optionsWithoutSelf = entries.filter(([key]) => key !== notUsableParameter)
        return optionsWithoutSelf.map(([label]) => {
            const name = updateEntityLabelIntoName(label)
            return { label: name, value: label }
        })
    }
    return []
}

const updateEntityLabelIntoName = (value: string) => {
    switch (value) {
        case 'werkmaatschappijs': return 'Werkmaatschappijen';
        case 'factuur-documents': return 'Facturen';
        case 'loonstrook-documents': return 'Loonstroken';
        case 'jaaropgave-documents': return 'Jaaropgave';
        case 'vestigings': return 'Vestigingen';
        case 'debiteurs': return 'Debiteuren';
        case 'mijnhandtekening-documents': return 'Mijnhandtekening';
        case 'lijstwerk-documents': return 'Lijstwerk';
        case 'jaarwerk-documents': return 'Jaarwerk';
        case 'idchecker-documents': return 'Idchecker';
        default: return value.replace('-',' ').replace('_',' ');
    }
}
