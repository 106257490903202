import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from "react-oidc-context";
import { CssBaseline, ThemeProvider } from '@mui/material';
import "simplebar-core/dist/simplebar.css";
import { Provider } from 'react-redux';

import { App } from './App';
import { theme } from './theme';
import { store } from './store/store';
import { getConfig } from './helpers';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const config = {
    ...getConfig().oidc,
    redirect_uri: window.location.origin
}

root.render(
    <React.StrictMode>
        <AuthProvider {...config}>
            <ThemeProvider theme={theme}>
                <CssBaseline />

                <Provider store={store}>
                    <App />
                </Provider>
            </ThemeProvider>
        </AuthProvider>
    </React.StrictMode>
);
