const oidcConfig = [
    {
        baseUrl: "https://5b243a3d-e44e-4501-bf70-01856f0e4e05.eu-west-1.contentgrid.cloud/",
        name: "RGF Staffing - Production",
        oidc: {
            authority: "https://auth.eu-west-1.contentgrid.cloud/realms/cg-cb748e71-5ff5-4220-916f-b90f55d59ad4",
            client_id: "rgfnl-web"
        }
    },
    {
        baseUrl: "https://b77ffeac-72ef-4377-bcf1-abe14cb35ab7.eu-west-1.contentgrid.cloud/",
        name: "RGF Staffing - Acceptance",
        oidc: {
            authority: "https://auth.eu-west-1.contentgrid.cloud/realms/cg-cb748e71-5ff5-4220-916f-b90f55d59ad4",
            client_id: "rgfnl-web"
        }
    },
    {
        baseUrl: "https://f9163ece-5740-41d6-911f-957c3c99727e.eu-west-1.contentgrid.cloud/",
        name: "Anonymized",
        oidc: {
            authority: "https://auth.eu-west-1.contentgrid.cloud/realms/cg-cb748e71-5ff5-4220-916f-b90f55d59ad4",
            client_id: "rgfnl-web"
        }
    },
    {
        baseUrl: "https://2e53620c-93ca-4f40-8cfb-10038e3ef062.eu-west-1.contentgrid.cloud/",
        name: "Development",
        oidc: {
            authority: "https://auth.eu-west-1.contentgrid.cloud/realms/cg-cb748e71-5ff5-4220-916f-b90f55d59ad4",
            client_id: "rgfnl-web"
        }
    },
]

export default oidcConfig;
