import { styled, MenuItem } from "@mui/material"
import { NavLink } from "react-router-dom";
import cx from 'classnames'
import { useDispatch } from "react-redux";

import { theme } from "../theme";
import { useEffect } from "react";
import { toggleForm } from "../store/formSlice";

interface NavMenuProps {
    selected: string,
    options: {label: string, value: string}[],
}

export const NavMenu = ({ selected, options }: NavMenuProps) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(toggleForm(true))
    },[selected, dispatch])

    return (
        <StyledWrapper>
            {options.map(option => {
                const classes = cx({ 'is-selected': option.value === selected })

                return (
                    <StyledItem
                        key={option.value}
                        value={option.value}
                    >
                        <StyledNavLink className={classes} to={option.value}>{option.label}</StyledNavLink>
                    </StyledItem>
                )
            })}
        </StyledWrapper>
    )
}

const StyledWrapper = styled('ul')({
    padding: 0,
    margin: 0,
    maxWidth: '100%'
})

const StyledItem = styled(MenuItem)({
    borderRadius: '0.25rem',
    padding: '0',
    '&:not(:last-child)': {
        marginBottom: '1rem'
    }
})

const StyledNavLink = styled(NavLink)({
    textDecoration: 'none',
    width: '100%',
    padding: '0.5rem 1rem',
    borderRadius: '0.25rem',
    border: '1px solid '+theme.palette.grey[100],
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textTransform: 'capitalize',
    '&:focus-visible': {
        outline: 0,
        backgroundColor: theme.palette.grey[300],
        border: '1px solid '+theme.palette.grey[300],
    },
    '&:hover': {
        backgroundColor: theme.palette.grey[100],
    },
    '&.is-selected': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        }
    },
})
