import { styled } from "@mui/styles"
import { Button, Collapse } from "@mui/material";
import validator from "@rjsf/validator-ajv8";
import Form from "@rjsf/mui";
import { RJSFSchema } from "@rjsf/utils";
import { IChangeEvent } from "@rjsf/core";
import { useMemo } from "react";

import { CollapseButton } from "../button/CollapseButton";
import { TextGroup } from "./TextGroup";
import { DateGroup } from "./DateGroup";
import { FormTemplate } from "./FormTemplate";
import { transformErrors } from "../../components/SearchForm/helpers";

const widgets = {
    TextWidget: TextGroup,
    DateWidget: DateGroup, 
}

interface SearchFormProps {
    schema: RJSFSchema | null,
    opened: boolean,
    formData?: {[k: string]: string | undefined},
    onCollapseButtonClick: () => void,
    onChange: (value: IChangeEvent) => void,
    onSubmit: () => void
}

export const SearchJsonForm = ({ formData, schema, opened, onCollapseButtonClick, onChange, onSubmit }: SearchFormProps) => {
    const buttonText = useMemo(() => {
        if(!!formData){
            return Object.values(formData).filter(item => !!item).length > 0 ? 'Zoek' : 'Toon alles'
        }
        return 'Toon alles'
    },[formData])

    return (
        <>
            <CollapseButton opened={opened} onClick={onCollapseButtonClick} />

            <Collapse in={opened} appear={opened}>
                {!!schema && (
                    <StyledForm>
                        <Form
                            liveValidate
                            schema={schema}
                            formData={formData}
                            validator={validator}
                            uiSchema={{ "ui:ObjectFieldTemplate": FormTemplate }}
                            showErrorList={false}
                            transformErrors={transformErrors}
                            widgets={widgets}
                            onChange={onChange}
                        >

                        <StyledButton>
                            <Button id='submit-button' variant='contained' onClick={onSubmit}>{buttonText}</Button>
                        </StyledButton>
                        </Form>
                    </StyledForm>
                )}
            </Collapse>
        </>
    )
}

const StyledForm = styled('div')({
    maxWidth: '40rem',
    marginBottom: '2rem'
})

const StyledButton = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    '&.is-disabled': {
        opacity: 0.6,
        pointerEvents: 'none'
    }
})
