import { styled } from "@mui/styles"
import { IconButton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

interface ClearButtonProps {
    ariaLabel?: string,
    onClear: () => void,
}

export const ClearButton = ({ ariaLabel = "clear-field", onClear }: ClearButtonProps) => {
    return (
        <StyledIconButton
            aria-label={ariaLabel}
            onClick={onClear}
        >
            <ClearIcon fontSize='small' color='action' />
        </StyledIconButton>
    )
}

const StyledIconButton = styled(IconButton)({
    position: 'absolute',
    right: '0.25rem',
    top: '0.45rem',
    padding: '0.25rem'
})

