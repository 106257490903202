import { ObjectFieldTemplateProps } from "@rjsf/utils"

export const FormTemplate = (props: ObjectFieldTemplateProps) => {
  return (
    <div>
      {props.properties?.map(element => {
        return (
          <div key={element.name}>{element.content}</div>
        )
      })}
    </div>
  )
}
