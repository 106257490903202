import { useAuth } from "react-oidc-context";
import { styled } from "@mui/system";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Worker } from "@react-pdf-viewer/core";

import { Login } from './components/Login';
import { Header } from "./components/Header";
import { FormPage } from "./components/FormPage";
import { Main } from "./components/Main";
import { ResultsPage } from "./components/ResultsPage";
import { LoadingPage } from "./components/LoadingPage";

export const App = () => {
  const auth = useAuth();

    switch (auth.activeNavigator) {
      case "signinSilent":
          return <div>Signing you in...</div>;
      case "signoutRedirect":
          return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <LoadingPage />
    }

    if (auth.error) {
        return <Login onClick={() => void auth.signinRedirect()} />
    }

    if (auth.isAuthenticated) {
        return (
            <BrowserRouter>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js">
                <StyledWrapper>

                    <Header onClick={() => void auth.removeUser()}>{auth.user?.profile.preferred_username}</Header>

                    <Routes>
                        <Route path='/' element={<Main />}>
                            <Route path=':slug' element={<FormPage />}>
                                <Route path='search' element={<ResultsPage />} />
                            </Route>
                        </Route>
                    </Routes>
                </StyledWrapper>
                </Worker>
            </BrowserRouter>
        );
    }

    return <Login onClick={() => void auth.signinRedirect()} />
}

const StyledWrapper = styled('div')({
    height: '100vh',
    overflow: 'hidden'
})
