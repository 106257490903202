import { useEffect } from "react"
import { Outlet, useParams } from "react-router-dom";

import fetcher from '../repository';
import { PageBlockWrapper } from "../ui/PageBlockWrapper";
import { SearchForm } from "./SearchForm/SearchForm";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { selectSearchData, updateSearchData } from "../store/searchDataSlice";
import { RootState } from "../store/store";

export const FormPage = () => {
    const { slug } = useParams();
    const dispatch = useDispatch()
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

    useEffect(() => {
        if(!!slug){
            fetcher.getEntityProfileData(slug).then(data => {
                dispatch(updateSearchData(data))
            })
        }
    },[slug, dispatch])

    const formData = useTypedSelector(selectSearchData)
    const title = !!formData ? formData.title : 'Select Document'

    return (
        <PageBlockWrapper title={title}>
            {formData !== null ? <SearchForm formData={formData} /> : null}

            <Outlet />
        </PageBlockWrapper>
    )
}
