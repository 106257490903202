import MUIDataTable, {SelectableRows} from 'mui-datatables'
import { ForwardedRef, forwardRef, useCallback, useState } from 'react';
import { Alert, Box, Button, CircularProgress, alpha, styled } from '@mui/material';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import JSZip from 'jszip'
import { saveAs } from "file-saver";

import { tableOptions } from '../variables';
import { RootState } from '../../../../store/store';
import { selectSearchData } from '../../../../store/searchDataSlice';
import { getColumns, updateRowDates } from '../helpers';
import fetcher from '../../../../repository';
import { ProfileQueryEmbeddedItemData } from '../../../../repository/models';
import { HttpResponseError } from '../../../../repository/HttpError';

interface TableBodyProps {
    rows: ProfileQueryEmbeddedItemData[],
    title: string,
    selectedIndex: number | null,
    onSelectIndex: (value: number | null) => void
}

export const TableBody = forwardRef(({ rows, title, selectedIndex, onSelectIndex }: TableBodyProps, ref: ForwardedRef<any>) => {
    const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

    const handleRowClick = (_rowData: string[], rowMeta: { dataIndex: number; rowIndex: number; }) => {
        const isNotSelectedText = !window?.getSelection()?.toString()
        

        if(isNotSelectedText){
            const index = rowMeta.rowIndex
            if(index !== selectedIndex){
                onSelectIndex(index)
                return
            }
            onSelectIndex(null)
        }
    }

    const updatedOptions = {
        ...tableOptions,
        selectableRows: !rows.length || !rows[0]._links.content?.href ? 'none' as SelectableRows : 'multiple',
        onRowClick: handleRowClick,
    }

    const data = useTypedSelector(selectSearchData)

    const columns = getColumns(rows, data)

    return (
        <StyledWrapper id={selectedIndex?.toString()} ref={ref}>
            <MUIDataTable
                title={title}
                data={updateRowDates(rows)}
                columns={columns}
                options={{
                    ...updatedOptions,
                    customToolbarSelect: (selectedRows, _displayData, setSelectedRows) => {
                        return (
                            <ZipArchiveLoader
                                rows={rows}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                            />
                        )
                    }
                }}
                
            />
        </StyledWrapper>
    ) 
})

const StyledWrapper = styled('div')(({ theme, id }) => ({
    height: 'max-content',
    ['& tr[data-testid="MUIDataTableBodyRow-'+id+'"]']: {
        backgroundColor: alpha(theme.palette.primary.main, 0.1)+'!important'
    }
}))

interface ZipArchiveLoaderProps {
    rows: ProfileQueryEmbeddedItemData[],
    selectedRows: { data: Array<{ index: number; dataIndex: number }>; lookup: { [key: number]: boolean } },
    setSelectedRows: (rows: number[]) => void
}

const ZipArchiveLoader = ({rows, selectedRows, setSelectedRows}: ZipArchiveLoaderProps) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<HttpResponseError | null>(null)


    const handleClick = useCallback(async() => {
        setLoading(true)
        setError(null)

        const lookup = Object.keys(selectedRows.lookup)
        const selectedList = rows.filter((_row, index) => {
            return lookup.includes(String(index))
        })

        const zip = new JSZip();

        const pdfFetcher = await Promise.all(
            selectedList.map(async (item) => {
                const res = await fetcher.getContent(item._links.content?.href)
                return {
                    blob: res,
                    fileName: 'content_filename' in item ? item.content_filename as string : null
                }
            })
        );

        pdfFetcher.forEach((pdf, index) => {
            const fileName = !!pdf.fileName ? pdf.fileName : `document_${index+1}.pdf`
            zip.file(fileName, pdf.blob);
        });

        zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, "export-selection.zip");
        }).catch(error => setError(error))

        setLoading(false)
    }, [rows, selectedRows])

    return (
        <Box minHeight={48} paddingRight={2} display='flex' alignItems='center'>
            {!!error ? <Box marginRight={1}><Alert severity='error'>{error.message}</Alert></Box> : null}
            <Button disabled={loading} onClick={handleClick} variant='outlined'>
                {loading ? <Box component='span' display='inline-flex' marginRight={1}><CircularProgress size={16}/></Box> : null}
                Download zip archive</Button>
        </Box>
    )
}
