import { createTheme } from "@mui/material";
import { Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
    interface Components {
      [key: string]: any
    }
  }


export const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    boxSizing: 'border-box'
                },
                html: {
                    width: '100%',
                    height: '100%',
                },
                body: {
                    width: '100%',
                    height: '100%',
                    WebkitFontSmoothing: 'antialiased',
                    MozOsxFontSmoothing: 'grayscale',
                    margin: 0,
                    color: '#2e3538',
                    backgroundColor: '#f0f7fa',
                    '& #root': {
                        height: '100%'
                    }
                },
                code: {
                    fontFamily: 'source-code-pro, "Menlo", "Monaco", "Consolas", "Courier New", monospace',
                },
                'input::-webkit-calendar-picker-indicator': {
                    position: 'absolute',
                    zIndex: 1,
                    width: '1.5rem',
                    height: '1.5rem',
                    left: 0,
                    right: 0,
                    margin: '0 auto',
                    backgroundColor: '#fff',
                    textAlign: 'center',
                    cursor: 'pointer'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                contained: {
                    boxShadow: 'none',
                    minHeight: '2.5rem',
                    boxSizing: 'border-box',
                    '&:hover': {
                        boxShadow: 'none',
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    boxSizing: 'border-box',
                    padding: '0.5rem 1rem',
                    minHeight: '2.5rem',
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    border: '1px solid #cfcfcf',
                    borderRadius: '0.25rem',
                    '&:hover': {
                        borderColor: '#a2a2a2'
                    },
                    '&.Mui-focused': {
                        borderWidth: '1px',
                        borderColor: '#0065bd',
                        boxShadow: 'none',
                    },
                    '&.Mui-focused .MuiSelect-iconOpen': {
                        color: '#0065bd'
                    },
                    '& fieldset.MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    '&.MuiInput-root': {
                        '&::before': {
                            display: 'none'
                        },
                        '&::after': {
                            display: 'none'
                        }
                    },
                    '& .MuiButtonBase-root.MuiIconButton-root': {
                        marginRight: '0.5rem'
                    }
                },
                input: {
                    '&.MuiInput-input': {
                        height: '2.5rem',
                        boxSizing: 'border-box',
                        padding: '0 0.5rem'
                    }
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '&.MuiMenu-list': {
                        padding: '0.5rem'
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&.MuiInputLabel-root': {
                        position: 'static',
                        color: '#2e3538',
                        transform: 'none',
                        textAlign: 'right',
                        maxWidth: '100%'
                    },
                    '& + .MuiInputBase-root.MuiInput-root': {
                        marginTop: 0,
                    },
                    '&.Mui-focused': {
                        color: '#0065bd',
                        fontSize: '1rem'
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: '1rem',
                    '& .MuiFormHelperText-root': {
                        margin: 0
                    },
                    '& .MuiTextField-root': {
                        display: 'grid',
                        gridTemplateColumns: '11rem 1fr',
                        alignItems: 'baseline',
                        gridColumnGap: '1rem',
                        marginBottom: '0',
                    },
                    '& .MuiList-root.MuiList-dense': {
                        position: 'absolute',
                        right: 0,
                        bottom: '-30px'
                    },
                    '& .form-group.field-error.has-error': {
                        marginBottom: '1rem'
                    }
                }
            }
        },
        MUIDataTable: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&.MuiSelect-standard.MuiInputBase-input.MuiInput-input': {
                        lineHeight: '2.5rem'
                    }
                }
            }
        },
        MUIDataTableToolbar: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid #e0e0e0'
                }
            }
        },
        MUIDataTableSearch: {
            styleOverrides: {
                searchText: {
                    margin: 0,
                    maxWidth: 'fit-content'
                }
            }
        }
    },
    palette: {
        primary: {
            light: '#dbdfe1',
            main: '#0065bd',
            dark: '#005197',
            contrastText: '#fff',
        },
        text: {
            primary: '#2e3538',
        }
    },
    typography: {
        htmlFontSize: 16,
        fontSize: 14,
        fontFamily: [
            '-apple-system',
            '"BlinkMacSystemFont"',
            '"Segoe UI"',
            '"Roboto"',
            '"Oxygen"',
            '"Ubuntu"',
            '"Cantarell"',
            '"Fira Sans"',
            '"Droid Sans"',
            '"Helvetica Neue"',
            'sans-serif'
        ].join(','),
    },
});
