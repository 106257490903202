import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, styled } from "@mui/material"
import { ExpandLess, ExpandMore, LogoutOutlined } from "@mui/icons-material"
import { ReactNode, useState } from "react"

import { theme } from "../theme"

interface HeaderMenuProps {
    title: ReactNode
    onClick: () => void
}

export const HeaderMenu = ({ title, onClick }: HeaderMenuProps) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
      setOpen((active) => !active);
    };

    return (
        <StyledList aria-labelledby="nested-list-profile">
            <ListItemButton sx={{ borderRadius: '0.25rem' }} onClick={handleClick}>
                <ListItemText primary={title} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit >
                <StyledNestedList disablePadding>
                <ListItemButton sx={{ pl: 4 }} onClick={onClick}>
                    <ListItemIcon>
                    <LogoutOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Uitloggen" />
                </ListItemButton>
                </StyledNestedList>
            </Collapse>
        </StyledList>
    )
}

const StyledList = styled(List)({
    width: 'auto',
    backgroundColor: 'transparent',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    paddingTop: '1rem'
})

const StyledNestedList = styled(List)({
    width: 'auto',
    borderRadius: '0.25rem',
    overflow: 'hidden',
    color: theme.palette.text.primary,
    backgroundColor: '#fff',
    boxShadow: '0px 3px 16px 0px #b6b6b6'
})


