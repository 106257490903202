import { InputBase, styled } from "@mui/material";
import cx from 'classnames'
import { forwardRef } from "react";

type TextInputProps = {
    autoFocus?: boolean,
    fullWidth?: boolean,
    placeholder?: string,
    handleOnChange?: (value: string) => void,
    handleOnKeyDown?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    value?: string,
    short?: boolean,
    className?: string,
    disabled?: boolean,
    id?: string,
    type?: string,
    maxLength?: number,
    onBlur?: () => void,
    onFocus?: () => void,
}

export const TextInput = forwardRef(({
    className,
    short,
    placeholder,
    value,
    autoFocus,
    fullWidth,
    disabled,
    id,
    type,
    maxLength = 1000,
    handleOnChange,
    handleOnKeyDown,
    onBlur,
    onFocus,
}: TextInputProps, ref) => {
    const classes = cx({
        'is-short': short
    }, className)

    const inputProps = { maxLength: maxLength }

    return <StyledInput
            ref={ref}
            className={classes}
            placeholder={placeholder}
            onChange={e => { handleOnChange && handleOnChange(e.target.value) }}
            onKeyDown={(e) => { handleOnKeyDown && handleOnKeyDown(e) }}
            value={value}
            autoFocus={autoFocus}
            fullWidth={fullWidth}
            disabled={disabled}
            id={id}
            type={type}
            inputProps={inputProps}
            onBlur={onBlur}
            onFocus={onFocus}
            />
})

TextInput.displayName = "TextInput"

const StyledInput = styled(InputBase)({
    padding: '0 0.5rem',
    minHeight: '2.5rem',

    short: {
        maxWidth: "20ch",
        display: "inline-block"
    }
})
