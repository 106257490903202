import { Outlet } from "react-router-dom";
import { styled } from "@mui/system";

import { Sidebar } from "./Sidebar";
import { Scroll } from "../ui/Scroll";

export const Main = () => {
    return (
        <StyledMainWrapper>
            <Sidebar />

            <Scroll>
              <Outlet />
            </Scroll>

        </StyledMainWrapper>
    )
}

const StyledMainWrapper = styled('div')({
    display: 'grid',
    gridTemplateColumns: '20rem 1fr',
    paddingTop: '5rem',
    height: '100%'
})
