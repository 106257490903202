import { useEffect } from "react"
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Alert, Box, CircularProgress } from "@mui/material";

import { PageBlockWrapper } from "../ui/PageBlockWrapper";
import { Table } from "../ui/table/Table/Table";
import { PageNavigation } from "../ui/Navigation";
import { toggleForm } from "../store/formSlice";
import { useQueryEntityData } from "../hooks/useQueryEntityData";

export const ResultsPage = () => {
    const { slug } = useParams()
    const dispatch = useDispatch()
    const {data, error} = useQueryEntityData(slug!)

    useEffect(() => {
        if(!!data?.page.totalElements){
            dispatch(toggleForm(false))
        }
    },[data, dispatch])

    const isCompleteData = !!data && !!slug

    const results = isCompleteData ? data._embedded[slug] : null
    
    const quantity = isCompleteData ? data.page.totalElements : 0

    const resultsTitle = !!results?.length ? 'Resultaten: '+quantity : ''

    if(error !== null && error?.statusCode === 403){
        return <Alert severity='error'>No Permissions.</Alert>
    }

    return (
        <PageBlockWrapper
            withoutSpacings
            header={quantity > 20 ? (
                <Header
                    nextLink={data?._links.next?.href ?? ''}
                    prevLink={data?._links.prev?.href ?? ''}
                />
            ) : null}
        >
            {results === null ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></Box>
            ) : <></>}

            <Table rows={results} title={resultsTitle} />
        </PageBlockWrapper>
    )
}

interface HeaderProps {
    prevLink?: string,
    nextLink?: string
}

const Header = ({ nextLink, prevLink }: HeaderProps) => {
    return (
        <PageNavigation nextLink={nextLink} prevLink={prevLink} />
    )
}
