import { configureStore } from "@reduxjs/toolkit";

import { openedFormReducer } from "./formSlice";
import { searchDataReducer } from "./searchDataSlice";

export const store = configureStore({
  reducer: {
    openedForm: openedFormReducer,
    searchData: searchDataReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
