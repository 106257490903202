import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from "./store";
import { ProfileEntityData } from '../repository/models';

export const selectSearchData = (state: RootState) => state.searchData;

type SearchDataStateType = ProfileEntityData | null; 
const initialState = null as SearchDataStateType

export const searchDataSlice = createSlice ({
  name: "searchData",
  initialState,
  reducers: {
    updateSearchData: (
        state: SearchDataStateType,
        { payload }: PayloadAction<SearchDataStateType>
      ) => (
        state = payload
      )
  }
})

export const { updateSearchData } = searchDataSlice.actions;

export const searchDataReducer = searchDataSlice.reducer;
