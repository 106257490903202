import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import { IconButton, styled } from "@mui/material"

interface CollapseButtonProps {
  opened: boolean,
  onClick: () => void,
}

export const CollapseButton = ({ opened, onClick }: CollapseButtonProps) => {
  return (
    <StyledIconWrapper>
        <StyledIcon color='primary' onClick={onClick}>
            {opened ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
        </StyledIcon>
    </StyledIconWrapper>
  )
}

const StyledIconWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '2px',
  background: theme.palette.grey[300],
  marginBottom: '2rem'
}))

const StyledIcon = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  background: '#eee',
  padding: '0.25rem',
  left: 0,
  right: 0,
  margin: '0 auto',
  top: '-1rem',
  backgroundColor: theme.palette.primary.main,
  width: '2rem',
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  }
}))
