import { Button, MenuItem, Select, SelectChangeEvent, alpha } from "@mui/material"
import { styled } from "@mui/styles"

import { useEffect, useState } from "react"
import oidcConfig from "../oidc-config"
import { isProduction } from "../helpers"

interface LoginProps {
    onClick: () => void
}

export const Login = ({ onClick }: LoginProps) => {
    const isProd = isProduction()

    if(isProd){
        onClick()
    }

    return (
        <StyledWrapper>
            {!isProd ? <DataSelect /> : <></>}

            <Button variant="contained" disableElevation onClick={onClick}>Log in</Button>
        </StyledWrapper>
    )
}

const StyledWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
})

const DataSelect = () => {
    const [ value, setValue ] = useState(oidcConfig[0].baseUrl)

    const handleChange = (e: SelectChangeEvent<unknown>) => {
        const val = e.target.value as string
        setValue(val)
    }
    useEffect(() => {
        const config = oidcConfig.find(item => item.baseUrl === value)
        window.localStorage.setItem('config', JSON.stringify(config))
    }, [value])

    return (
        <Select sx={{ marginRight: '2rem' }} value={value} onChange={handleChange}>
            {oidcConfig.map(option => {
                return (
                    <StyledItem key={option.baseUrl} value={option.baseUrl}>{option.name}</StyledItem>
                )
            })}
        </Select>
    )
}

const StyledItem = styled(MenuItem)(({ theme }) => ({
    borderRadius: '0.25rem',
    padding: '0',
    '&.Mui-selected': {
        backgroundColor: alpha(theme.palette.primary.main, 0.5),
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.5),
        }
    },
    '&.Mui-focused': {
        backgroundColor: '#e4e4e4'
    },
    '&:not(:last-child)': {
        marginBottom: '0.25rem'
    }
}))
