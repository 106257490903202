import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from "./store";

export const selectOpenedForm = (state: RootState) => state.openedForm;

const initialState: boolean = true

export const openedFormSlice = createSlice ({
  name: "openedForm",
  initialState,
  reducers: {
    toggleForm: (
        state: boolean, 
        action: PayloadAction<boolean>
      ) => (
        state = action.payload
      )
  }
})


export const { toggleForm } = openedFormSlice.actions;

export const openedFormReducer = openedFormSlice.reducer;
