import { Box, styled } from "@mui/material";
import { WidgetProps } from "@rjsf/utils";
import { useEffect, useState } from "react";

import { ClearButton } from "../button/ClearButton";
import { TextInput } from "./TextInput";
import { replaceDateSigns, typeDate } from "../../components/SearchForm/helpers";

export const DateGroup = ({ id, label, value, onChange }: WidgetProps) => {
    const [localValue, setLocalValue] = useState('')

    const handleChange = (val: string) => {
        const date = typeDate(val)
        setLocalValue(date)
    }

    const handleDateChange = (val: string) => {
        const currentValue = replaceDateSigns(val, '-', '/')
        setLocalValue(currentValue)
    }

    useEffect(() => {
        if(localValue.length === 10){
            const val = replaceDateSigns(localValue, '/', '-')
            onChange(val)
            return
        }
        if(localValue === '' && !!value){
            onChange('')
        }
    },[onChange, localValue, value])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if(event.key === 'Enter'){
            document.getElementById('submit-button')?.click()
        }
    }

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: '11rem 1fr', columnGap: '1rem', alignItems: 'center' }}>
            <label htmlFor={id}>{label}</label>

            <TextInput
                id={id}
                placeholder='mm/dd/yyyy'
                value={localValue}
                maxLength={10}
                handleOnChange={handleChange}
                handleOnKeyDown={handleKeyDown}
            />

            <DatePicker value={value} onChange={handleDateChange} />

            {!!localValue && (
                <Box sx={{ position: 'absolute', right: '2rem', top: '0px' }}>
                    <ClearButton onClear={() => setLocalValue('')} />
                </Box>
            )}
        </Box>
    )
}

interface DatePickerProps {
    value: string,
    onChange: (value: string) => void
}

const DatePicker = ({ value, onChange }: DatePickerProps) => {
    return (
        <StyledInput tabIndex={1} type='date' value={value} onChange={(e) => onChange(e.target.value)} />
    )
}

const StyledInput = styled('input')({
    position: 'absolute',
    width: '1.5rem',
    height: '1.5rem',
    backgroundColor: 'transparent',
    border: '0',
    cursor: 'pointer',
    right: '0.5rem',
    '&:focus': {
        border: 0,
        outline: 0
    }
})
