import { useNavigate } from "react-router-dom";
import { Button, styled } from "@mui/material";
import cx from 'classnames'

interface PageNavigationProps {
    nextLink?: string,
    prevLink?: string
}

export const PageNavigation = ({ nextLink, prevLink }: PageNavigationProps) => {
    const navigate = useNavigate()

    const handleNext = () => {
        if(!!nextLink){
            const link = nextLink.split('?')[1]
            navigate(`?${link}`)
        }
    }

    const handlePrev = () => {
        if(!!prevLink){
            const link = prevLink.split('?')[1]
            navigate(`?${link}`)
        }
    }

    const prevClasses = cx({ 'is-disabled': !prevLink })
    const nextClasses = cx({ 'is-disabled': !nextLink })

    return (
        <StyledWrapper>
            <StyledButton className={prevClasses} variant='contained' type="submit" onClick={handlePrev}>Vorige</StyledButton>

            <StyledButton className={nextClasses} variant='contained' type="submit" onClick={handleNext}>Volgende</StyledButton>
        </StyledWrapper>
    )
}

const StyledWrapper = styled('div')({
    display: 'flex',
    height: '2.5rem'
})

const StyledButton = styled(Button)({
    '&:not(:last-child)': {
        marginRight: '1rem'
    },
    '&.is-disabled': {
        opacity: 0.6,
        pointerEvents: 'none'
    }
})
