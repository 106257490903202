import { styled } from "@mui/system";
import SimpleBar from 'simplebar-react';
import { ReactNode } from "react";
import { theme } from "../theme";

interface ScrollProps {
    children: ReactNode
}

export const Scroll = ({ children }: ScrollProps) => {
    return (
        <StyledWrapper>
            {children}
        </StyledWrapper>
    )
}

const StyledWrapper = styled(SimpleBar)({
    overflowX: 'hidden',
    maxHeight: 'calc(100vh - 5rem)',
    '& .simplebar-scrollbar::before': {
        backgroundColor: theme.palette.grey[500]
    },
    '& .simplebar-track.simplebar-vertical': {
        marginRight: '0.25rem',
        marginTop:'1rem',
        marginBottom: '1rem'
    }
})
