import { Box, Typography, styled } from '@mui/material';

import { theme } from '../theme';

export const Signature = () => {
    return (
        <StyledWrapper>
            <Box component='a' href='https://contentgrid.com/' sx={{ display: 'flex', marginRight: '1rem' }}>
                <img width="32" height="32" src="../favicon.png" alt="ContentGrid logo" />
            </Box>

            <Typography variant='body2'>Powered by content<Box component='span' sx={{ color: theme.palette.primary.main }}>grid</Box></Typography>
        </StyledWrapper>
    )
}

const StyledWrapper = styled('div')({
    position: 'absolute',
    bottom: '1rem',
    display: 'flex',
    alignItems: 'center',
    left: 0,
    right: 0,
    justifyContent: 'center'
})
